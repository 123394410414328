<template>
  <section class="separator text-light d-none d-lg-block">
    <div class="container">
      <div class="d-flex align-items-center justify-content-between bg-dark">
        <div>
          <div class="icon d-inline-block align-middle">
            <b-img
              src="@/base/assets/icon-clock-co.png"
              width="24"
              height="25"
              alt="Delivery"
              fluid
            ></b-img>
          </div>
          <span class="d-inline-block align-middle"
            ><cmsBlockSimple identifier="order_today_tomorrow_at_home"
          /></span>
        </div>
        <div class="mx-10">
          <div class="icon d-inline-block align-middle">
            <b-img
              src="@/base/assets/icon-delivery-co.png"
              width="24"
              height="25"
              alt="Delivery"
              fluid
            ></b-img>
          </div>
          <span class="d-inline-block align-middle"
            ><cmsBlockSimple identifier="free_shipping"
          /></span>
        </div>
        <div>
          <div class="icon d-inline-block align-middle">
            <b-img
              src="@/base/assets/icon-tick-co.png"
              width="18"
              height="18"
              alt="Tick"
              fluid
            ></b-img>
          </div>
          <span class="d-inline-block align-middle"
            ><cmsBlockSimple identifier="our_advantages"
          /></span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import cmsBlockSimple from "@/base/core/components/core/BlockSimple";

export default {
  name: "FooterDeliverySeparator",
  components: { cmsBlockSimple },
};
</script>
