<template>
  <b-collapse
    :id="'topIconsCollapse' + showType + icon.iconNumber"
    :class="{ collapsed: visible }"
    :aria-expanded="visible"
    class="top-icons--drop cart"
    ref="cart"
    v-model="visible"
    @shown="recalculateHeight"
  >
    <component :is="`style`" v-html="dropDownStyle"> </component>
    <span
      v-if="icon.dropHeading"
      class="drop-heading"
      v-html="icon.dropHeading.replace(/&gt;/g, '>').replace(/&lt;/g, '<')"
    ></span>
    <ul class="drop-items">
      <li v-for="dropItem of icon.dropItems" :key="dropItem.dropItemId">
        <div class="d-flex align-items-start">
          <div class="drop-item--img">
            <b-img
              :src="dropItem.product.image.x_small"
              :alt="dropItem.product.name"
              width="39"
              height="39"
            >
            </b-img>
          </div>
          <div class="drop-item--info">
            <span class="product-name d-block">{{
              dropItem.product.name
            }}</span>
            <div v-if="typeof dropItem.configurable_options != 'undefined'">
              <span
                v-for="(option, index) of dropItem.configurable_options"
                :key="index"
                class="product-options d-block"
              >
                <span class="product-options-title"
                  >{{ option.option_label }}:&nbsp;</span
                >
                <span class="product-options-value">{{
                  option.value_label
                }}</span>
              </span>
            </div>
            <div v-if="typeof dropItem.bundle_options != 'undefined'">
              <div>
                <span
                  v-for="(option, index) of dropItem.bundle_options"
                  :key="index"
                  class="product-options d-block"
                >
                  <span>{{ option.label }}: </span>

                  <span
                    v-if="
                      typeof option.values[0].configurable_options !=
                      'undefined'
                    "
                  >
                    <span
                      v-for="(opt, idx) of option.values[0]
                        .configurable_options"
                      :key="idx"
                    >
                      <span class="product-options-title"
                        >{{ opt.option_label }}:&nbsp;</span
                      >
                      <span class="product-options-value">{{
                        opt.value_label
                      }}</span>
                    </span>
                  </span>
                </span>
              </div>
              <span
                v-for="(option, index) of dropItem.configurable_options"
                :key="index"
                class="product-options d-block"
              >
                <span class="product-options-title"
                  >{{ option.option_label }}:&nbsp;</span
                >
                <span class="product-options-value">{{
                  option.value_label
                }}</span>
              </span>
            </div>

            <div class="quantity-price-box">
              <div class="price-box price">
                &euro;&nbsp;
                {{
                  dropItem.prices.row_total_including_tax.value
                    .toFixed(2)
                    .replace(".", ",")
                }}
              </div>
              <div class="quantity-box">
                <div class="quantity-input d-flex">
                  <b-link
                    href="#"
                    class="step-down-btn"
                    @click="quantityDown(dropItem.id, dropItem.quantity)"
                  ></b-link>
                  <input
                    type="number"
                    min="1"
                    :value="dropItem.quantity"
                    name="quantity"
                    ref="inputQuantity"
                    disabled
                  />
                  <b-link
                    href="#"
                    class="step-up-btn"
                    @click="quantityUp(dropItem.id, dropItem.quantity)"
                  ></b-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          href="#"
          class="lnr lnr-cross"
          @click.prevent="removeItem(dropItem.id)"
        ></a>
      </li>
    </ul>
    <div class="d-flex drop-bottom align-items-center">
      <span class="total">
        {{ $t("total") }}: €
        {{ icon.cartFooter.totalPrice.toFixed(2).replace(".", ",") }}</span
      >
    </div>
    <div
      class="d-flex drop-bottom justify-content-between align-items-center"
      v-if="icon.cartFooter"
    >
      <b-link to="/checkout/cart">{{ $t("goto_cart") }}</b-link>
      <b-button
        variant="success"
        @click="$router.push('/checkout')"
        v-bind:disabled="btnDisable"
      >
        <ClientOnly>
          <i class="fa fa-check-circle"></i>
        </ClientOnly>
        <i class="lnr lnr-cart"></i>{{ icon.cartFooter.btnText }}
      </b-button>
    </div>
    <div class="language-block" v-if="icon.language">
      <ul class="d-flex list-unstyled">
        <li v-for="lang of icon.language" :key="lang.alt">
          <b-link :href="lang.href">
            <img :src="lang.img" :alt="lang.alt" />
          </b-link>
        </li>
      </ul>
    </div>
  </b-collapse>
</template>

<script>
// import { Logger } from "@storefront/core/lib/logger";
import Cart from "@storefront/core/modules/cart/mixins";
import ClientOnly from "vue-client-only";
import { isServer } from "@storefront/core/helpers";

import { mapActions } from "vuex";

export default {
  name: "HeaderIconsDropdownCart",
  components: {
    ClientOnly,
  },
  props: {
    icon: {
      type: Object,
    },
    showType: { type: String },
  },
  mixins: [Cart],
  computed: {
    btnDisable() {
      if (this.cartCount > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.closeDropdownMenu);
  },
  destroyed() {
    document.removeEventListener("click", this.closeDropdownMenu);
  },
  data() {
    return {
      visible: false,
      dropDownStyle: "max-height: calc(100vh - 0px) !important",
      divHeight: 0,
    };
  },
  methods: {
    ...mapActions(["setProductStatus"]),
    closeDropdownMenu(e) {
      if (
        this.$el.parentElement &&
        !this.$el.parentElement.contains(e.target)
      ) {
        this.visible = false;
        if (!isServer) {
          document.body.classList.remove("noScroll");
        }
      } else {
        this.visible = true;
        if (!isServer) {
          document.body.classList.add("noScroll");
        }
      }
    },
    closeOnClick() {
      this.visible = false;
      if (!isServer) {
        document.body.classList.remove("noScroll");
      }
    },
    calculateDivHeight() {
      if (!isServer) {
        const filterSectionDOM = this.$refs.cart.$el;
        var height = filterSectionDOM
          ? filterSectionDOM.getBoundingClientRect().top
          : 0;
        return height;
      } else {
        return 0;
      }
    },
    recalculateHeight() {
      if (!isServer) {
        const filterSectionDOM = this.$refs.cart.$el;
        var height = filterSectionDOM
          ? filterSectionDOM.getBoundingClientRect().top
          : 0;
        this.dropDownStyle =
          "#topIconsCollapseM3 { max-height: calc(100vh - " +
          height +
          "px) !important}";
      }
    },
  },
};
</script>
<style>
</style>