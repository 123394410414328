<template>
  <nav id="nav">
    <b-link href="#" class="nav-opener" @click="toggleNav"
      ><span></span
    ></b-link>
    <div
      class="nav-overlay"
      @click="toggleNav"
      :class="{ show: navExpanded }"
    ></div>
    <div class="fake-nav-overlay" :class="{ show: navExpanded }"></div>
    <transition name="slide-fade">
      <div class="nav-collapse" :class="{ show: navExpanded }">
        <NavbarContent @clicked="toggleNav" />
      </div>
    </transition>
  </nav>
</template>

<script>
import NavbarContent from "./NavbarContent.vue";

export default {
  name: "Navbar",
  components: {
    NavbarContent,
  },
  data: () => ({}),
  computed: {
    navExpanded: {
      get() {
        return this.$store.getters["menu/getNavbar"];
      },
      set() {
        //Nothing to do
      },
    },
  },
  mounted() {
    window.addEventListener("resize", this.resize);
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    onClick() {},
    toggleNav() {
      this.$store.dispatch("menu/toggleNavbar");
    },
    resize() {
      document.body.classList.add("resize-active");
      if (window.innerWidth > 991) this.navExpanded = false;
      clearTimeout(this.resizeTimer);
      this.resizeTimer();
    },
    resizeTimer() {
      setTimeout(() => {
        document.body.classList.remove("resize-active");
      }, 400);
    },
  },
};
</script>

