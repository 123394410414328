<template>
  <b-collapse
    :id="'topIconsCollapse' + showType + icon.iconNumber"
    :class="{ collapsed: visible }"
    :aria-expanded="visible"
    class="top-icons--drop--menu"
    v-model="visible"
  >
    <div>
      <ul class="list-unstyled">
        <li>
          <b-link @click="goAccount">{{ $t("account") }}</b-link>
        </li>
        <li>
          <b-link @click="goLogout">{{ $t("logout") }}</b-link>
        </li>
      </ul>
    </div>
  </b-collapse>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "HeaderIconsDropdown",
  props: {
    icon: {
      type: Object,
    },
    showType: { type: String },
  },
  mounted() {
    document.addEventListener("click", this.closeDropdownMenu);
  },
  destroyed() {
    document.removeEventListener("click", this.closeDropdownMenu);
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    ...mapActions(["setProductStatus"]),
    closeDropdownMenu(e) {
      if (!this.$el.parentElement.contains(e.target)) {
        this.visible = false;
      }
    },
    removeItem(id) {
      this.$store.dispatch("user/removeProductWishlist", {
        id: id,
      });
    },
    closeOnClick() {
      this.visible = false;
    },
    goAccount() {
      this.visible = false;
      this.$router.push("/account");
    },
    goLogout() {
      this.visible = false;
      this.$store.dispatch("user/logout");
      this.$router.push("/");
    },
  },
};
</script>


