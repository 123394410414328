import Vue from "vue";
import store from "@/store";
import config from "@storefront/core/lib/utils/config";
export default Vue.extend({
  computed: {
    /**
     *
     * @returns user/getWishlistQuantity store value
     */
    wishlistCount: function () {
      return store.getters["user/getWishlistQuantity"];
    },
    /**
     *
     * @returns user/getIsLoggedIn store value
     */
    isLoggedIn: function () {
      return store.getters["user/getIsLoggedIn"];
    },
    /**
     *
     * @returns cart/getItemsTotalQuantity store value if cart is loaded
     */
    cartCount: function () {
      if (store.getters["cart/getCartIsLoaded"] == true) {
        return store.getters["cart/getItemsTotalQuantity"];
      } else {
        return 0;
      }
    },
    /**
     *
     * @returns cart/getCartItems store value
     */
    cartItems: function () {
      return store.getters["cart/getCartItems"];
    },
    /**
     *
     * @returns user/getWishlist store value
     */
    wishlistItems: function () {
      return store.getters["user/getWishlist"];
    },
    /**
     *
     * @returns cart/getCartPrices grand total value or 0
     */
    cartTotalPrice: function () {
      if (store.getters["cart/getCartIsLoaded"] == true) {
        const prices = store.getters["cart/getCartPrices"];
        if (typeof prices != "undefined") {
          if (typeof prices.grand_total != "undefined") {
            if (typeof prices.grand_total.value != "undefined") {
              return prices.grand_total.value;
            }
          }
        }
        return 0;
      } else {
        return 0;
      }
    },
    /**
     *
     * @returns cart/getDefaultShipping store value
     */
    defaultShipping: function () {
      return store.getters["cart/getDefaultShipping"];
    },
    /**
     *
     * @returns cart/getCartPrices subtotal_including_tax.value store value
     */
    cartSubTotalPrice: function () {
      if (store.getters["cart/getCartIsLoaded"] == true) {
        const prices = store.getters["cart/getCartPrices"];
        if (typeof prices != "undefined") {
          if (typeof prices.subtotal_including_tax != "undefined") {
            if (typeof prices.subtotal_including_tax.value != "undefined") {
              return prices.subtotal_including_tax.value;
            }
          }
        }
        return 0;
      } else {
        return 0;
      }
    },
    /**
     *
     * @returns cart/getCartPrices applied_taxes store value
     */
    cartTotalTaxPrices: function () {
      if (store.getters["cart/getCartIsLoaded"] == true) {
        const prices = store.getters["cart/getCartPrices"];
        if (typeof prices != "undefined") {
          return prices.applied_taxes;
        }
        return [];
      } else {
        return [];
      }
    },
    /**
     *
     * @returns cart/getCartPrices discount store value
     */
    cartTotalDiscounts: function () {
      if (store.getters["cart/getCartIsLoaded"] == true) {
        const prices = store.getters["cart/getCartPrices"];
        if (typeof prices != "undefined") {
          return prices.discounts;
        }
        return [];
      } else {
        return [];
      }
    },
  },
  methods: {
    /**
     * Remove Product from Cart
     *
     * @param {integer} id
     */
    removeItem(id) {
      const found = this.cartItems.find((v) => {
        if (v.id == id) {
          return true;
        }
      });

      if (this.$gtm.enabled()) {
        if (!this.$gtm.uaDisabled) {
          window.dataLayer.push({
            event: "eec.remove",
            ecommerce: {
              add: {
                products: [
                  {
                    name: found.product.name,
                    id: found.product.sku,
                    quantity: this.quantity,
                  },
                ],
              },
            },
          });
        }
        if (this.$gtm.ga4Enabled) {
          //GA4 event
          window.dataLayer.push({
            event: "remove_from_cart",
            ecommerce: {
              add: {
                currency: "EUR",
                value: found.prices.row_total.value,
                items: [
                  {
                    item_name: found.product.name,
                    item_id: found.product.sku,
                    price: found.prices.price.value,
                    quantity: found.quantity
                  }
                ],
              },
            },
          });
        }
      }

      this.$store.dispatch("cart/removeCartItem", {
        id: id,
      });
      if (config.helloRetail && config.helloRetail.enabled) {
        setTimeout(function () { this.HrClearCart() }.bind(this), 1000)
      }

    },
    /**
     * Drops quantity of a product in the cart with 1
     *
     * @param {integer} id
     * @param {integer} quantity
     */
    quantityDown(id, quantity) {
      if (quantity > 0) {
        quantity--;
        this.$store.dispatch("cart/updateCartItem", {
          id: id,
          quantity: quantity,
        });
        const found = this.cartItems.find((v) => {
          if (v.id == id) {
            return true;
          }
        });

        if (this.$gtm.enabled()) {
          if (!this.$gtm.uaDisabled) {
            window.dataLayer.push({
              event: "eec.remove",
              ecommerce: {
                add: {
                  products: [
                    {
                      name: found.product.name,
                      id: found.product.sku,
                      quantity: quantity,
                    },
                  ],
                },
              },
            });
          }
          if (this.$gtm.ga4Enabled) {
            //GA4 event
            window.dataLayer.push({
              event: "remove_from_cart",
              ecommerce: {
                add: {
                  currency: "EUR",
                  value: found.prices.price.value * quantity,
                  items: [
                    {
                      item_name: found.product.name,
                      item_id: found.product.sku,
                      price: found.prices.price.value,
                      quantity: quantity
                    }
                  ],
                },
              },
            });
          }
        }
      }
      if (config.helloRetail && config.helloRetail.enabled) {
        setTimeout(function () { this.HrClearCart() }.bind(this), 1000)
      }

    },
    /**
     * Raises quantity of a product in the cart with 1
     *
     * @param {integer} id
     * @param {integer} quantity
     */
    quantityUp(id, quantity) {
      quantity++;
      this.$store.dispatch("cart/updateCartItem", {
        id: id,
        quantity: quantity,
      });
      const found = this.cartItems.find((v) => {
        if (v.id == id) {
          return true;
        }
      });

      if (this.$gtm.enabled()) {
        if (!this.$gtm.uaDisabled) {
          window.dataLayer.push({
            event: "eec.add",
            ecommerce: {
              add: {
                products: [
                  {
                    name: found.product.name,
                    id: found.product.sku,
                    quantity: quantity,
                  },
                ],
              },
            },
          });
        }
        if (this.$gtm.ga4Enabled) {
          //GA4 event
          window.dataLayer.push({
            event: "add_to_cart",
            ecommerce: {
              add: {
                currency: "EUR",
                value: found.prices.price.value * quantity,
                items: [
                  {
                    item_name: found.product.name,
                    item_id: found.product.sku,
                    price: found.prices.price.value,
                    quantity: quantity
                  }
                ],
              },
            },
          });
        }
      }
    },

    HrClearCart() {
      if (this.cartCount == 0) {
        self.ADDWISH_PARTNER_NS.api.cart.clearCart(function () {
          //Cart has been cleared
        });
      }
    }
  },
});
