<template>
  <ul class="top-icons">
    <li v-for="icon of icons" :key="icon.iconNumber">
      <b-link
        v-if="icon.isEnabled"
        :to="icon.link"
        @click.prevent="onClick"
        v-b-toggle="`topIconsCollapse` + showType + icon.iconNumber"
      >
        <i v-if="icon.isLinearIcon" class="lnr" :class="icon.iconName"></i>
        <div class="img" v-else>
          <b-img
            :src="icon.iconName"
            width="25"
            height="25"
            :alt="icon.text"
            fluid
          ></b-img>
        </div>
        <span>{{ icon.text }}</span>
        <b-badge v-if="icon.hasBadge" :variant="icon.badgeColor">{{
          icon.badgeValue
        }}</b-badge>
      </b-link>
      <HeaderIconsDropdownCart
        v-if="
          icon.hasDropdown && icon.menuId == 'cart' && icon.isEnabled == true
        "
        :icon="icon"
        :showType="showType"
      />
      <HeaderIconsDropdown
        v-if="
          icon.hasDropdown &&
          icon.menuId == 'wishList' &&
          icon.isEnabled == true
        "
        :icon="icon"
        :showType="showType"
      />
      <HeaderIconsDropdownMenu
        v-if="
          icon.hasDropdown && icon.menuId == 'account' && icon.isEnabled == true
        "
        :icon="icon"
        :showType="showType"
      />
    </li>
  </ul>
</template>
<script>
import HeaderIconsDropdown from "@/base/core/components/HeaderIconsDropdown";
import HeaderIconsDropdownCart from "@/base/core/components/HeaderIconsDropdownCart";
import HeaderIconsDropdownMenu from "@/base/core/components/HeaderIconsDropdownMenu";
import Cart from "@storefront/core/modules/cart/mixins";
import infoIcon from "@/base/assets/icon-info.png";
export default {
  name: "HeaderTopIcons",
  mixins: [Cart],
  components: {
    HeaderIconsDropdown,
    HeaderIconsDropdownCart,
    HeaderIconsDropdownMenu,
  },
  methods: {
    onClick() {},
  },
  props: {
    showType: { type: String },
  },
  computed: {
    icons() {
      return [
        {
          iconNumber: 1,
          isLinearIcon: true,
          hasBadge: false,
          iconName: "lnr-map-marker",
          text: "Onze winkel",
          hasDropdown: false,
          link: "/winkels",
          isEnabled: true,
        },
        {
          iconNumber: 2,
          isLinearIcon: false,
          hasBadge: false,
          iconName: infoIcon,
          text: "Klantenservice",
          hasDropdown: false,
          link: "/contact",
          isEnabled: true,
        },
        {
          iconNumber: 3,
          isLinearIcon: true,
          hasBadge: true,
          badgeValue: this.cartCount,
          badgeColor: "success",
          iconName: "lnr-cart",
          text: "Winkelmand",
          isEnabled: true,
          hasDropdown: true,
          dropHeading: this.$t("items_in_cart", { numberOf: this.cartCount })
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">"),
          dropItems: this.cartItems,
          menuId: "cart",
          cartFooter: {
            totalPrice: this.cartSubTotalPrice,
            btnText: this.$t("goto_checkout"),
            btnVariant: "success",
          },
          link: "#",
        },
        {
          iconNumber: 4,
          isLinearIcon: true,
          hasBadge: true,
          badgeValue: this.wishlistCount,
          badgeColor: "dark",
          iconName: "lnr-heart",
          text: "Wensenlijst",
          hasDropdown: true,
          menuId: "wishList",
          isEnabled: this.isLoggedIn,
          dropHeading: this.$t("items_in_wishlist", {
            numberOf: this.wishlistCount,
          }),
          dropItems: this.wishlistItems,
        },
        {
          iconNumber: 5,
          isLinearIcon: true,
          hasBadge: false,
          iconName: "lnr-user",
          text: this.$t("login"),
          hasDropdown: false,
          link: "/login",
          isEnabled: !this.isLoggedIn,
        },
        {
          iconNumber: 6,
          isLinearIcon: true,
          hasBadge: false,
          iconName: "lnr-user",
          text: this.$t("account"),
          menuId: "account",
          hasDropdown: true,
          isEnabled: this.isLoggedIn,
        },
      ];
    },
  },
};
</script>
