<template>
  <transition name="back-to-top-fade">
    <client-only>
      <div
        class="vue-back-to-top"
        :style="`bottom:${this.bottom};right:${this.right};`"
        v-show="visible"
        @click="backToTop"
      >
        <slot>
          <div class="default">
            <span>
              {{ text }}
            </span>
          </div>
        </slot>
      </div>
    </client-only>
  </transition>
</template>

<script>
import ClientOnly from "vue-client-only";
export default {
  name: "BackToTop",
  components: {
    ClientOnly,
  },
  props: {
    text: {
      type: String,
      default: "Voltar ao topo",
    },
    visibleoffset: {
      type: [String, Number],
      default: 600,
    },
    visibleoffsetbottom: {
      type: [String, Number],
      default: 0,
    },
    right: {
      type: String,
      default: "30px",
    },
    bottom: {
      type: String,
      default: "40px",
    },
    scrollFn: {
      type: Function,
      default: function () {},
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  mounted() {
    window.smoothscroll = () => {
      const currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(window.smoothscroll);
        window.scrollTo(0, Math.floor(currentScroll - currentScroll / 5));
      }
    };
    window.addEventListener("scroll", this.catchScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.catchScroll);
  },
  methods: {
    /**
     * Catch window scroll event
     * @return {void}
     */
    catchScroll() {
      const pastTopOffset = window.pageYOffset > parseInt(this.visibleoffset);
      const pastBottomOffset =
        window.innerHeight + window.pageYOffset >=
        document.body.offsetHeight - parseInt(this.visibleoffsetbottom);
      this.visible =
        parseInt(this.visibleoffsetbottom) > 0
          ? pastTopOffset && !pastBottomOffset
          : pastTopOffset;
      this.scrollFn(this);
    },
    /**
     * The function who make the magics
     * @return {void}
     */
    backToTop() {
      window.smoothscroll();
      this.$emit("scrolled");
    },
  },
};
</script>


