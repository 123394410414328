<template>
  <div class="footer-copyright">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="align-self-center" v-html="getCopyright"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterCopyRight",
  components: {},
  mounted() {},
  methods: {},
  computed: {
    getCopyright() {
      let copyright =
        this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
          "footer_copyright"
        );
      const date = new Date();
      copyright = copyright.content
        .replace(
          /copyright/i,
          "Copyright &copy; " +
            date.getFullYear() +
            ' | powered by <a href="https://e-tailors.nl/" title="e-tailors" target="_blank">e-tailors</a>'
        )
        .replace(
          /<b-link((?!<\/b-link>).+?)to="(.*?)<\/b-link>/g,
          '<a$1href="$2</a>'
        );
      return copyright;
    },
  },
  watch: {},
  data: () => ({}),
};
</script>

