<template>
  <div class="separator text-uppercase text-center text-light">
    <cmsBlockSimpleTitle identifier="header_seperator_title" />
  </div>
</template>

<script>
import cmsBlockSimpleTitle from "@/base/core/components/core/BlockSimpleTitle";
export default {
  name: "HeaderSeparator",
  components: { cmsBlockSimpleTitle },
};
</script>


