<template>
  <b-collapse
    :id="index.toString()"
    :class="visible ? null : 'collapsed'"
    :aria-expanded="visible ? 'true' : 'false'"
    class="footer--drop"
    v-model="visible"
  >
    <cmsBlockSimple
      v-if="typeof accordionData == 'object'"
      :identifier="accordionData.identifier"
    />
  </b-collapse>
</template>

<script>
import cmsBlockSimple from "@/base/core/components/core/BlockSimple";

export default {
  name: "FooterAccordion",
  props: ["accordionData", "index"],
  components: { cmsBlockSimple },
  data: () => ({
    visible: false,
  }),
  methods: {
    close(e) {
      if (!this.$el.parentElement.contains(e.target)) {
        this.visible = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.close);
  },
  destroyed() {
    document.removeEventListener("click", this.close);
  },
};
</script>

