<template>
  <b-collapse :id="collapseId" class="sub-menu" v-model="visible">
    <div v-if="!mobileCheck" ref="submenu">
      <ul v-for="menu in menuArray" :key="'menu' + menuArray.indexOf(menu)">
        <li v-for="(menuItem, i) in menu" :key="menuItem.id">
          <div v-if="i < 24">
            <b-link v-on:click="closeInternal" :to="menuItem.linkTo">{{
              CapitalizeFirst(menuItem.name)
            }}</b-link>
          </div>
          <div v-if="readMore['menu' + menuArray.indexOf(menu)] && i >= 24">
            <b-link v-on:click="closeInternal" :to="menuItem.linkTo">{{
              CapitalizeFirst(menuItem.name)
            }}</b-link>
          </div>
        </li>
        <div v-if="menu.length > 24">
          <div v-if="!readMore['menu' + menuArray.indexOf(menu)]">
            <b-link
              style="display: block; cursor: pointer; font-weight: 500"
              @click.stop="showMore('menu' + menuArray.indexOf(menu))"
              >{{ $t("show_more") }}</b-link
            >
          </div>

          <div v-if="readMore['menu' + menuArray.indexOf(menu)]">
            <b-link
              style="display: block; cursor: pointer; font-weight: 500"
              @click.stop="showLess('menu' + menuArray.indexOf(menu))"
            >
              {{ $t("show_less") }}
            </b-link>
          </div>
        </div>
      </ul>
    </div>
    <div v-else>
      <ul
        v-for="menu in menuArray"
        :key="'menu' + menuArray.indexOf(menu)"
        id="`menu${menu.id}`"
      >
        <li v-for="menuItem in menu" :key="menuItem.id">
          <div class="mobile-sub-wrap">
            <b-link @click="closeInternal" :to="menuItem.linkTo">{{
              menuItem.name
            }}</b-link>
            <b-link
              @click.stop="
                $root.$emit('bv::toggle::collapse', `subMenu${menuItem.id}`)
              "
              ><i v-if="menuItem.length" class="fa fa-chevron-right"></i
            ></b-link>
          </div>
          <b-collapse v-if="menuItem.length" :id="`subMenu${menuItem.id}`">
            <ul class="submenu-child">
              <li v-for="subItem in menuItem" :key="subItem.id">
                <b-link
                  v-if="subItem.include_in_menu"
                  :to="`/${subItem.url_path}`"
                  >{{ subItem.name }}</b-link
                >
              </li>
            </ul>
          </b-collapse>
        </li>
      </ul>
    </div>
  </b-collapse>
</template>

<script>
import { isMobile, isTablet } from "mobile-device-detect";

export default {
  name: "HeaderSubmenu",
  props: ["menuArray", "collapseId", "modelValue", "id"],
  data: () => ({
    readMore: {},
  }),
  computed: {
    mobileCheck() {
      if (isMobile || isTablet) {
        return true;
      }
      return false;
    },
    mob() {
      if (isMobile) {
        return true;
      }
      return false;
    },
    mobtab() {
      if (isTablet) {
        return true;
      }
      return false;
    },
    visible: {
      get() {
        return this.$store.getters["menu/getMenuItemState"](this.id).state;
      },
      set(val) {
        this.$store.commit("menu/updateMenuState", { id: this.id, state: val });
      },
    },
  },
  methods: {
    close(e) {
      if (!this.$el.contains(e.target.nextSibling)) {
        this.visible = false;
      }
    },
    closeInternal() {
      this.visible = false;
      this.$store.commit("menu/setNavBar", false);
    },
    showMore(id) {
      this.$set(this.readMore, id, true);
      this.setMenuHeight();
    },
    showLess(id) {
      this.$set(this.readMore, id, false);
      this.setMenuHeight();
    },
    onOver(subMenuId) {
      this.subMenuId = subMenuId;
    },
    onLeave() {
      this.subMenuId = null;
    },
    setMenuHeight() {
      this.$nextTick(() => {
        const subMenu = this.$refs["sub-menu"];
        if (subMenu) {
          const height = subMenu.clientHeight;
          this.$emit("setHeight", height);
        }
      });
    },
    resetMenuHeight() {
      this.$emit("resetHeight");
    },
    CapitalizeFirst(input) {
      let lower = input.toLowerCase();
      return lower.charAt(0).toUpperCase() + lower.slice(1);
    },
  },
  mounted() {
    document.addEventListener("click", this.close);
  },
  destroyed() {
    document.removeEventListener("click", this.close);
  },
  watch: {
    visible(value) {
      if (value == true) {
        this.setMenuHeight();
      }
      if (value == false) {
        this.resetMenuHeight();
      }
    },
  },
};
</script>

