<template>
  <div>
    <span class="mobile-menu-header d-lg-none">{{
      $t("navbar_choose_category")
    }}</span>
    <b-link class="nav-opener inner-close" @click="toggleNav"></b-link>
    <ul class="nav-menu">
      <li class="nav-item">
        <span class="nav-link has-no-submenu"
          ><b-link to="/" v-on:click="closeInternal">{{
            $t("home")
          }}</b-link></span
        >
      </li>
      <li
        class="nav-item"
        v-for="navItem of navItems"
        :key="navItem.id"
        @mouseover="onOver(navItem.id)"
        @mouseleave="onLeave(navItem.id)"
      >
        <div class="nav-link" @click="onToggle(navItem.id)">
          <b-link
            :to="navItem.linkTo"
            @click.stop="onClick(navItem.id, navItem.linkTo)"
            >{{ navItem.itemName }}</b-link
          >
        </div>

        <HeaderSubmenu
          v-if="navItem.hasDropMenu"
          :menuArray="navItem.dropMenu"
          :collapseId="'submenu' + navItem.id"
          :id="navItem.id"
          @setHeight="setHeight"
          @resetHeight="resetHeight"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import HeaderSubmenu from "@/base/core/components/HeaderSubmenu";
export default {
  name: "NavbarContent",
  components: {
    HeaderSubmenu,
  },
  data: () => ({
    menuHeight: {
      default: 315,
      current: 315,
    },
  }),
  computed: {
    navItems() {
      return this.$store.getters["menu/getMenuItems"];
    },
  },
  methods: {
    closeInternal() {
      this.visible = false;
      this.$store.commit("menu/setNavBar", false);
    },
    onOver(id) {
      if (window.innerWidth > 991) {
        this.$store.commit("menu/updateMenuState", { id: id, state: true });
      }
    },
    onToggle(id) {
      if (window.innerWidth <= 991) {
        const vis = this.$store.getters["menu/getMenuItemState"](id);

        if (vis.state == false) {
          this.$store.commit("menu/updateMenuState", { id: id, state: true });
        } else {
          this.$store.commit("menu/updateMenuState", { id: id, state: false });
        }
      }
    },
    onLeave(id) {
      if (window.innerWidth > 991) {
        this.$store.commit("menu/updateMenuState", { id: id, state: false });
      }
    },
    onClick(id, route) {
      this.$store.commit("menu/updateMenuState", { id: id, state: false });
      this.$store.commit("menu/setNavBar", false);

      this.$router.push(route).catch(() => {});
    },
    toggleNav(event) {
      this.$emit("clicked", event);
    },
    isActiveNavItem(id) {
      return this.$store.getters["menu/getMenuItemState"](id).state;
    },
    setHeight(height) {
      if (height > this.menuHeight.default) {
        this.menuHeight.current = height;
      } else {
        this.resetHeight();
      }
    },
    resetHeight() {
      this.menuHeight.current = this.menuHeight.default;
    },
  },
};
</script>
