<template>
  <div :class="containerClass">
    <b-carousel id="carousel-1" v-model="slide" class="d-none d-md-block">
      <b-carousel-slide
        v-for="(bSlide, index) in sliderDesktop.slides"
        :key="index"
      >
        <template #img>
          <b-link :to="bSlide.link" v-if="bSlide.link">
            <img :src="bSlide.optImage" :alt="bSlide.title" />
          </b-link>
          <span v-else>
            <img :src="bSlide.optImage" :alt="bSlide.title" />
          </span>
        </template>
      </b-carousel-slide>
    </b-carousel>
    <b-carousel id="carousel-2" v-model="slide" class="d-md-none d-block">
      <b-carousel-slide
        v-for="(bSlide, index) in sliderMobile.slides"
        :key="index"
      >
        <template #img>
          <b-link :to="bSlide.link" v-if="bSlide.link">
            <img :src="bSlide.optImage" :alt="bSlide.title" />
          </b-link>
          <span v-else>
            <img :src="bSlide.optImage" :alt="bSlide.title" />
          </span>
        </template>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
import { isMobile } from "mobile-device-detect";
import { isTablet } from "mobile-device-detect";
import { isBrowser } from "mobile-device-detect";

export default {
  name: "DiscountCode",
  data: () => ({
    slide: 0,
  }),
  computed: {
    sliderDesktop() {
      var sliderName = "discount_header";
      var slider =
        this.$store.getters["sliders/getSliderByIdentifier"](sliderName);
      if (slider.slides != null) {
        slider.slides.forEach((element) => {
          element.optImage = element.media.url;
        });
      }
      return slider;
    },
    sliderMobile() {
      var sliderName = "discount_header_mobile";
      var slider =
        this.$store.getters["sliders/getSliderByIdentifier"](sliderName);
      if (slider.slides != null) {
        slider.slides.forEach((element) => {
          element.optImage = element.media.url;
        });
      }
      return slider;
    },
    mobileCheck() {
      if (isBrowser) {
        return "browser";
      }
      if (isMobile && isTablet) {
        return "tablet";
      }
      return "mobile";
    },
    containerClass() {
      if (this.mobileCheck == "browser" || this.mobileCheck == "tablet") {
        return "container";
      } else {
        return "";
      }
    },
  },
};
</script>

