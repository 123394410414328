<template>
  <b-collapse
    :id="'topIconsCollapse' + showType + icon.iconNumber"
    :class="{ collapsed: visible }"
    :aria-expanded="visible"
    class="top-icons--drop"
    v-model="visible"
  >
    <span v-if="icon.dropHeading" class="drop-heading">{{
      icon.dropHeading
    }}</span>
    <ul class="drop-items">
      <li v-for="(dropItem, index) of icon.dropItems" :key="`wl-${index}`">
        <b-link
          :to="`/${dropItem.product.url_key}`"
          class="d-flex align-items-start"
          @click="closeOnClick"
        >
          <div class="drop-item--img">
            <img
              :src="dropItem.product.image.x_small"
              :alt="dropItem.product.sku"
            />
          </div>
          <div class="drop-item--info">
            <span class="product-name d-block">{{
              dropItem.product.name
            }}</span>
            <span class="price d-block"
              >&euro;{{
                dropItem.product.price_range.minimum_price.final_price.value.toFixed(
                  2
                )
              }}</span
            >
          </div>
        </b-link>
        <a
          href="#"
          class="lnr lnr-cross"
          @click.prevent="removeItem(dropItem.id)"
        ></a>
      </li>
    </ul>
    <div
      class="d-flex drop-bottom justify-content-between align-items-center"
      v-if="icon.cartFooter"
    >
      <span class="total">Total: € {{ icon.cartFooter.totalPrice }}</span>
      <b-button variant="success">
        <i class="fas fa-check-circle"></i>
        <i class="lnr lnr-cart"></i>{{ icon.cartFooter.btnText }}
      </b-button>
    </div>
    <div class="language-block" v-if="icon.language">
      <ul class="d-flex list-unstyled">
        <li v-for="lang of icon.language" :key="lang.alt">
          <b-link :href="lang.href"
            ><b-img
              :src="lang.img"
              :alt="lang.lang"
              width="39"
              height="39"
              fluid
            ></b-img>
          </b-link>
        </li>
      </ul>
    </div>
  </b-collapse>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "HeaderIconsDropdown",
  props: {
    icon: {
      type: Object,
    },
    showType: { type: String },
  },
  mounted() {
    document.addEventListener("click", this.closeDropdownMenu);
  },
  destroyed() {
    document.removeEventListener("click", this.closeDropdownMenu);
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    ...mapActions(["setProductStatus"]),
    closeDropdownMenu(e) {
      if (!this.$el.parentElement.contains(e.target)) {
        this.visible = false;
      }
    },
    removeItem(id) {
      this.$store.dispatch("user/removeProductWishlist", {
        id: id,
      });
    },
    closeOnClick() {
      this.visible = false;
    },
  },
};
</script>

